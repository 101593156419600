$(document).on('click', '.navbar-minimalize', ev => {
  if ($('body').hasClass('mini-navbar')) {
    window.localStorage.setItem('mini-navbar', '1');
  } else {
    window.localStorage.removeItem('mini-navbar');
  }
});

$(document).ready(() => {
  const miniNavbar = window.localStorage.getItem('mini-navbar');

  if (miniNavbar) {
    $('body').addClass('mini-navbar');
  }
});
